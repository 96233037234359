$(function () {
  'use strict'

  // Menu/Hamburger trigger
  $('[data-toggle="offcanvas"]').on('click', function () {
    $('.offcanvas-collapse').toggleClass('open');
    $(this).toggleClass('is-active');
    $('body').toggleClass('has-menu-open');
  })

  // Filters trigger
  $('[data-toggle="filter-offcanvas"]').on('click', function () {
    $('.filters--offcanvas-collapse').toggleClass('open');
    $(this).toggleClass('is-active');
  })

  // Chosen JS
  $('#filters-location-county').chosen({width: "100%"});
  $('#filters-location-areas').chosen({
      placeholder_text_multiple: "Select a Location"
  });
  $('#landing-search-location').chosen({
      placeholder_text_multiple: "Select a Location"
  });
  $('#landing-search-type').chosen({
      disable_search: true,
      width: "100%"
  });
  $('#landing-search-property').chosen({
      disable_search: true
  });

  //For demo purposes
  $('.filters__list a').click(function(){
    $(this).toggleClass('is-ticked');
  });
});

// Timeline
$(window).on("load", function() {

  $(".timeline").each(function () {
  	var oContainer = $(this);
  	var startItemId = '';
  	var timelineSections = [];
  	var timelineRangeArray = [];
  	var timelineSectionArray = [];

  	$(oContainer).find("div.sectionCard").each(function (index) {
  		var containerGroupArr = $(this).attr("data-group").split(' - ');
  		var containerGroupStruct = {
  			'startYear': containerGroupArr[0],
  			'endYear': containerGroupArr[1]
  		};
  		timelineSections.push(containerGroupStruct);
  		timelineRangeArray.push('100');
  		timelineSectionArray.push(containerGroupArr[0] + ' - ' + containerGroupArr[1]);
  	});

  	// Read the ID of the first timeline element
  	startItemId = $(oContainer).find(".item.contentCard[data-id]").first().attr("data-id");

  	// Load the timeline
  	$(oContainer).find(".gbTimeline").each(function () {
  		$(this).timeline({
  			itemMargin: 30,
  			swipeOn: true,
  			openTriggerClass: '.read_more',
  			startItem: startItemId,
  			categories: timelineSectionArray,
  			nuberOfSegments: timelineRangeArray,
  			yearsOn: false
  		});
  	});

  	// Remove the month line
  	$(oContainer).find(".t_line_month").remove();

  });
});

// adapt the coloration of the navbar while scroll
$(window).scroll(function() {
    var scroll = getCurrentScroll();
    if (scroll > 50) {
        $('body').addClass('has-scrolled');
    } else {
        $('body').removeClass('has-scrolled');
    }
});

function getCurrentScroll() {
    return window.pageYOffset;
}


// -------------------------------------------------
// equalizeHeight() : levels selected elements to the same height
// -------------------------------------------------

function equalizeHeight(element) {
    var maxHeight = 0;
    element.css("height", "");
    element.each(function () {
        if ($(this).outerHeight() > maxHeight) {
            maxHeight = $(this).outerHeight();
        }
    });
    if (maxHeight > 0) {
        element.css("height", maxHeight);
    }
}